<template>
  <div v-if="userInfo != null">
    <div class="hxDetail" v-if="detail.id">
      <div class="box">
        <div class="t">{{detail.goodInfo.title}}</div>
        <div class="con">
          <div v-if="detail.status==1" class="t-c font-color-red">
            <p class="font-size-60"><van-icon name="warning" /></p>
            <p class="font-size-20 mt5">待支付</p>
          </div>
          <template v-if="detail.status==2">
          <div class="qr" v-if="uto_uid==undefined">
            <p id="qrcode"></p>
            <p>请将此二维码展示给商家核销</p>
          </div>
          <div class="action" v-else><van-button type="primary" @click="()=>{this.hxModal=true}">立即核销</van-button></div>
          </template>
          <div v-if="detail.status==3" class="t-c font-color-green">
            <p class="font-size-60"><van-icon name="checked" /></p>
            <p class="font-size-20 mt5">已核销</p>
          </div>
        </div>
      </div>
      <div class="box mt10">
        <div class="h4">订单详情</div>
        <div class="con">
          <p>订单编号：{{detail.ordernumber}}</p>
          <p>下单时间：{{detail.create_time}}</p>
          <p v-if="detail.payment_time != null">支付时间：{{detail.payment_time}}</p>
          <p v-if="detail.lq_time != null">领取时间：{{detail.lq_time}}</p>
        </div>
        
      </div>
      <div class="box mt10">
        <shopInfo :data="detail.hdInfo.shopInfo" v-if="detail.id"/>
      </div>
    
      <van-popup v-model="hxModal" position="bottom" :style="{ height: '100px',background:'#ddd',padding:'15px 15px 0px 15px',width:'calc(100% - 30px)' }">
        <div>
          <van-field v-model="hxpassword" center clearable placeholder="请输入核销密码" :error="error" @input="()=>{this.error=false}">
            <template #button>
              <van-button size="small" type="primary" @click="onSubmitHx">确认</van-button>
            </template>
          </van-field>
        </div>
        <div class="font-color-red mt10">注：由商家输入密码核销</div>
      </van-popup>
    </div>
    <global :hdid="detail.hdid" v-if="detail.id"/>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  import global from './components/global'
  import shopInfo from './components/shopInfo'
  import {GetorderInfo,OrderHx} from "./services/services";
  import {mapGetters} from 'vuex'
  export default {
    name:'scanHx',
    components: {shopInfo,global},
    data() {
      return {
        orderId:0,
        uid:undefined,
        uto_uid:undefined,
        detail:{},
        hxModal:false,
        hxpassword:'',
        error:false
      }
    },
    computed:{
      ...mapGetters(['snapshotuser','userInfo']),
    },
    created(){
      const {uid} = this.$route.query
      if(uid==undefined){
        this.uid=this.userInfo.id
      }else{
        this.uid=this.uto_uid=uid
      }
    },
    mounted() {
      const {orderid} = this.$route.params
      if(orderid==undefined){
        this.$toast.fail('参数错误');
      }else{
        this.orderId=parseInt(orderid)
        this.findDetail()
      }

    },
    methods: {
      findDetail(){
        GetorderInfo({id:this.orderId,uid:this.uid}).then(result => {
          if(result.data.code==1){
            this.detail=result.data.data
            if(this.uto_uid==undefined && this.detail.status==2){
              let qr=this.detail.hdInfo.url+'/wx/scan/hx/'+this.detail.id+'?uid='+this.detail.uid
              this.qrcode(qr)
            }
          }else{
            this.$toast(result.data.msg)
          }
        })
      },
      qrcode(url){
        this.$nextTick(() => {
          new QRCode('qrcode',{
            width:200, // 设置宽度，单位像素
            height:200, // 设置高度，单位像素
            text: url // 设置二维码内容或跳转地址
          })
        })
      },
      onSubmitHx(){
        if(this.hxpassword==''){
          this.error=true
          return false
        }
        OrderHx({id:this.orderId,uid:this.uid,hxpassword:this.hxpassword}).then(result => {
          if(result.data.code==1){
            this.$toast('核销成功')
            this.detail.status=3
            this.hxModal=false
          }else{
            this.$toast(result.data.msg)
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .hxDetail{padding:10px;
    .box{background:#fff;border-radius:4px;height:auto;
      .h4{font-weight:700;padding:10px;border-bottom:1px solid #ddd;}
      .t{padding:10px;border-bottom:1px solid #ddd;}
      .con{padding:10px;color:#999;
        p{line-height:24px;padding:5px 0px;}
      }
      .qr{padding:15px;height:auto;text-align:center;color:#666;
        p{padding:4px 0px;display:flex;justify-content:center;}
        #qrcode{height:auto;}
      }
      .action{padding:30px 0px;text-align:center;}
    }
  }
</style>
